import React, { useContext, useState } from "react"
import { PageContext } from "../../context/PageContext"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import coverImage from "../../images/Brochure.png"
import Button from "../atoms/Button"
import * as styles from "./BrochureDownloadable.module.scss"

type BrochureDownloadableProps = {
  id: string | null
  title: string | null
  // TODO?
  files: readonly (File | null)[]
  defaultFile?: File
  coverImage: JSX.Element | null
}

type File = {
  readonly locale: string | null
  readonly url: string | null
  readonly title: string | null
  readonly id: string | null
}

// TODO move Dato specific things to DatoBrochure and make this CMS/data layer agnostic

const BrochureDownloable = (props: BrochureDownloadableProps) => {
  // console.log("BrochureDownloable",props)
  const { locale } = useContext(PageContext)

  const [file, setFile] = useState<File | undefined>(props.defaultFile)

  const t = useDatoMicrocopy("common", locale)
  const lbl = useDatoMicrocopy("locale", locale)

  if (!props.files) {
    return null
  }

  return (
    <div className={styles.container}>
      {props.coverImage ? (
        <div className={styles.coverImage}>{props.coverImage}</div>
      ) : (
        <img className={styles.coverImage} src={coverImage} alt="" />
      )}

      <div className={styles.options}>
        {props.title && <h3 className={styles.title}>{props.title}</h3>}
        <ul className={styles.checks}>
          {props.files.map((file, i) =>
            file && file.locale ? (
              <li key={i}>
                <label>
                  <input
                    type="radio"
                    name={"brochure-" + props.id}
                    defaultChecked={file.id === props.defaultFile?.id}
                    onChange={() => {
                      setFile(file)
                    }}
                  />
                  {lbl(file.locale, file.locale.toUpperCase())}
                </label>
              </li>
            ) : null
          )}
        </ul>
        {file?.url && (
          <a href={file.url} target="_blank" download>
            <Button label={t("downloadBrochure", "Download brochure")} tabIndex={-1} />
          </a>
        )}
      </div>
    </div>
  )
}

export default BrochureDownloable
