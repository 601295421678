import { graphql } from "gatsby"
import React, { useContext } from "react"
import { PageContext } from "../../context/PageContext"
import { localeSort, localeValue } from "../../utils"
import BrochureDownloadable from "../molecules/BrochureDownloadable"
import DatoImage from "./DatoImage"

const DatoBrochure = (props: Queries.DatoBrochureFragment) => {
  // TODO move Dato specific things here and make BrochureDownloadable CMS/data layer agnostic
  const { locale } = useContext(PageContext)

  const { files, coverImage, ...rest } = props

  // Sortataan (tämä ehkä TODO?) ja  tiedostot
  const sortedFiles = localeSort(files).map(f => (f?.value ? { ...f.value, locale: f.locale } : null))
  const defaultFile = localeValue(locale, files)

  const image = coverImage ? <DatoImage image={coverImage.image} alt={coverImage.alt || ""} /> : null

  return (
    <BrochureDownloadable
      {...rest}
      files={sortedFiles}
      defaultFile={defaultFile ? { ...defaultFile, locale } : undefined}
      coverImage={image}
    />
  )
}

export default DatoBrochure

export const query = graphql`
  fragment DatoBrochure on DatoCmsBrochure {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    # TODO?
    files: _allFileLocales {
      value {
        id: originalId # to have a key for map loop
        url
        title
      }
      locale
    }
    coverImage {
      image: gatsbyImageData(width: 300)
      alt
    }
  }
`
