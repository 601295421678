import { graphql } from "gatsby"
import React from "react"
import DatoBrochure from "../DatoBrochure"
import * as styles from "./DatoBrochureList.module.scss"

const DatoBrochureList = (props: Queries.DatoBrochureListBlockFragment) => {
  //console.debug("Rendering DatoBrochureList", props)
  return (
    <section className="layout-container">
      {props.title && <h2>{props.title}</h2>}
      {props.ingress && <p className={styles.ingress}>{props.ingress}</p>}

      <ul className={styles.list}>
        {props.brochures?.map(brochure =>
          brochure ? (
            <li key={brochure.id}>
              <DatoBrochure {...brochure} />
            </li>
          ) : null
        )}
      </ul>
    </section>
  )
}

export default DatoBrochureList

export const query = graphql`
  fragment DatoBrochureListBlock on DatoCmsBrochureList {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    ingress
    brochures {
      ...DatoBrochure
    }
  }
`
